<template>
  <div>
    <div class="row">
      <div class="col-lg-8 py-1">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-white">Identifier</span>
          </div>
          <input id="id" name="id" class="form-control" required="required" v-model="id" style="min-width:150px"
                 :disabled="!startedEmpty" @keyup.enter="reloadWithId()">
        </div>
      </div>
      <div class="col-lg-2 py-1">
        <b-button name="submit" type="button" class="btn-custom btn-block" @click="reloadWithId()"
                  :disabled="id === undefined"> Search
        </b-button>
      </div>
      <div class="col-lg-2 py-1">
        <b-button name="submit" type="button" class="btn-custom btn-block" @click="reset()"> Reset</b-button>
      </div>
    </div>

    <div class="row my-1" v-if="loading">
      <div class="col">
        Loading
      </div>
    </div>
    <div class="row my-1" v-if="!loading && !error.present && !startedEmpty">
      <div class="col">
        You will be forwarded to the product data in {{ this.secondsLeft }} seconds <a href="#" @click="route()">or by
        clicking here</a>
      </div>
    </div>
    <div class="row my-1" v-if="error.present">
      <div class="col">
        {{ error.msg }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'identRouting',
  data: function () {
    return {
      id: this.$route.params.id,
      lgtin: '',
      loading: false,
      error: {
        present: false,
        msg: ''
      },
      startedEmpty: true,
      secondsLeft: 6
    };
  },
  methods: {
    countdown: function () {
      this.secondsLeft -= 1;
      if (this.secondsLeft <= 0) {
        this.route();
      }
    },
    getLgtin: function () {
      this.startedEmpty = false;
      this.error.present = false;

      this.loading = true;
      this.$axios
        .get('/api/marking_identifier/' + this.id, {})
        .then(response => {
          if (!response.data.lgtin) {
            this.error.present = true;
            this.error.msg = 'No LGTIN in response.';
            this.flashMessage.error({
              title: 'Error fetching LGTIN',
              message: this.error.msg
            });
          }

          this.lgtin = response.data.lgtin;
          this.flashMessage.success({
            title: 'Info message',
            message: 'Product for identifer ' + this.id + ' was found. Product LGTIN: ' + this.lgtin
          });
          console.info('Product for identifer ' + this.id + ' was found. Product LGTIN: ' + this.lgtin);

          setInterval(() => this.countdown(), 1000);
        })
        .catch(e => {
          this.error.present = true;

          switch (e.response.status) {
            case 404:
              this.error.msg = 'No LGTIN for this Marking Identifier found.'
              break;

            default:
              console.error('Error fetching LGTIN: ' + e);
              this.error.msg = e;
              this.flashMessage.error({
                title: 'Error fetching LGTIN',
                message: this.error.msg
              });
          }
        });
      this.loading = false;
    },
    route: function () {
      console.info('routing: GET /lgtin/' + this.lgtin);
      window.location.href = '/lgtin/' + this.lgtin;
    },
    reloadWithId: function () {
      console.info('routing: GET /lgtin/' + this.id);
      window.location.href = '/ident/' + this.id;
    },
    reset: function () {
      console.info('reset: GET /ident');
      window.location.href = '/ident';
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.startedEmpty = false;
      this.getLgtin();
    }
  }
};
</script>

<style scoped>
.btn-custom {
  color: #000000;
  background-color: #FFFFFF;
  border-color: #5794c4;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

</style>

<!-- Don't forget to include a preferred theme -->
<style src="highlight.js/styles/github.css"></style>
